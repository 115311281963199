<template>
  <b-modal
      id="showmodal"
      v-model="examDiv"
      title="切换考试报名库"
      size="lg"
      centered
      title-class="font-18"
      hide-footer
  >
    <div class="flexList">
      <el-select
          class="mr-2"
          style="width: 20%"
          placeholder="请选择考试年份"
          v-model="examParam.ksnf"
          size="small"
      >
        <el-option
            v-for="(item, i) in years"
            :label="item.label"
            :value="item.value"
            :key="i"
        >
        </el-option>
      </el-select>
      <input
          placeholder="关键字"
          class="h30 form-control border-blue mr-2"
          v-model="examParam.ksmc"
          maxlength="50"
          style="width: 156px"
      />
      <button
          type="button"
          class="btn btn-info h30 flexList mr-2"
          @click="getExamList"
      >
        <i class="iconfont mr-2 icon-mb-search"></i>查询
      </button>
    </div>
    <div class="table-responsive  mt-3" style="height: 500px;">
      <table class="table light-table table-hover border">
        <thead class="thead-light">
        <tr>
          <th>序号</th>
          <th>年份</th>
          <th>考试名称</th>
          <th>选择</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in examList" :key="item.sid">
          <td>{{ i + 1 }}</td>
          <td>{{ item.ksnf }}</td>
          <td>
            <a
                href="javascript:void(0)"
                @click="changeExam(item)"
                class="blue-font"
            >{{ item.ksmc }}</a
            >
          </td>
          <td>
            <b-button size="sm" variant="info" @click="changeExam(item)"
            >切换</b-button
            >
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>
import {getExamEasy, setNowExam
    } from "@/api/admin/exam/examRecord.js";
export default {
  name: "change-exam",
  data(){
    return {
      examDiv: false,
      examParam: {
        ksnf: "",
        ksmc: "",
      },
      examList: [],
      years:[],
      baseConfig: {},
    }
  },
  methods:{
    getYear() {
      //获取年份，当前年前后几年
      var y = new Date().getFullYear();
      for (var i = 0; i <= 5; i++) {
        if (i < 5) {
          this.years.unshift({
            value: y - i,
            label: y - i,
          });
        } else {
          for (var x = 1; x < 2; x++) {
            this.years.push({
              value: y + x,
              label: y + x,
            });
          }
        }
      }
      this.years.sort((a, b) => b.value - a.value);
    },
    // 获取考试列表
    getExamList() {
      getExamEasy(this.examParam).then((res) => {
        if (res.status) {
          this.examList = res.data;
        }
      });
    },
    // 切换考试
    changeExam(info) {
      setNowExam(info.ksbmbh).then(res=>{
        if(res.status){
          this.$router.go(0);
        }
      })
    }
  },
  mounted() {
    this.getYear()
    this.getExamList()
  },
  created() {
    let conf = JSON.parse(window.localStorage.getItem('BaseConfig'))
    this.baseConfig = conf ? conf : {}
    if (this.baseConfig) {
      this.examParam.ksnf = this.baseConfig.SystemYear?this.baseConfig.SystemYear:new Date().getFullYear()

    }
  }
}
</script>

<style scoped>

</style>
