<template>
  <b-modal
      v-model="showUser"
      centered
      title="选择默认审核范围"
      size="lg"
      title-class="font-18"
      hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">审核范围</label>
      <div class="col-sm-10 p-0 flexList">
        <el-checkbox-group v-model="chooseChecks" @change="change">
          <el-checkbox
              v-for="(check, i) in checkPowerList"
              :label="check.id"
              :key="i"
          >{{ check.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "change-exam",
  data(){
    return {
      chooseChecks: [],
      showUser: false
    }
  },
  methods:{
    show(){
      let store = this.getStore("chooseChecks")
      if (store){
        this.chooseChecks = store.split(",")
      } else {
        this.chooseChecks = []
      }
      this.showUser = true
    },
    change(){
      this.setStore("chooseChecks",  this.chooseChecks.join(","))
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>