<template>
  <b-modal
      v-model="showUser"
      centered
      title="切换审核方式"
      size="lg"
      title-class="font-18"
      hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label style="color: red">警告:请仔细根据考试实际情况确定审核方式，如果已设置数据再切换审核方式，所有已录入的审核权限设置信息都将被重置，请谨慎处理!</label>
    </div>
    <div class="flexList check-distri mb-3" v-for="(v, i) in modes" :key="i">
      <div>
        <el-radio v-model="mode" :label="v.num" style="width: 10px">
          {{v.num}}、{{v.des}}
        </el-radio>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="change">
        确定切换
      </button>
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>

import {getNowExam} from "@/api/admin/exam/examRecord";
import {updateExamCheckType} from "@/api/admin/exam/checkPower";

export default {
  data() {
    return {
      mode: 1,
      modes:[
        {link: "/admin/examinationDepartment", des: "按报考单位审核。即审核单位绑定若干个报考单位，在审核单位中再设置审核账号，只要属于审核单位的账号都可以审核被审核单位的报名信息，可以为审核账号设置操作权限。适用于公招考试等。", num: 1},
        {link: "/admin/examinationDepartmentByLocal",des: "按区域+专业方式审核。审核单位确定行政区域及所负责审核的专业，审核单位下设置若干账号，账号均可对所划定的区域考生及专业进行审核。适用于资格类考试。", num: 2},
        {link: "/admin/examinationAuthorityByRange",des: "按工作任务划分审核。即某一个账号设置多少审核任务，系统自动根据报名序号进行划分。", num: 3},
      ],
      ksInfo: {},
      showUser: false,
    };
  },
  methods: {
    show(){
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    change(){
      updateExamCheckType(this.ksInfo.ksbmbh, this.mode).then(res=>{
          if (res.status){
            this.modes.forEach(item=>{
              if (item.num == this.mode) {
                  window.location.href = item.link
              }
            })
          }
      })
    }
  },
  components: {
  },
  mounted() {
    getNowExam().then(res => {
      if (res.status) {
        this.ksInfo = res.data
        this.mode = Number(this.ksInfo.zgjgshshms)
        this.modes.forEach(item=>{
          if (item.num == this.ksInfo.zgjgshshms){
            if (this.$route.path != item.link){
              this.$router.push(item.link);
            }
          }
        })
      }
    })
  }
};
</script>

<style scoped>
.el-radio{
  white-space: normal;
  width: 100% !important;
}
/deep/ .el-radio__input{
  float: left !important;
}
/deep/ .el-radio__label{
  margin-left: 5px;
  display: grid;
  line-height: 20px;
}
</style>
